import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_nl_techleadmdx = graphql`
        query PageQuery_service_nl_techleadmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/techlead/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Project & Tech Lead",
  "path": "/service/techlead",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "nl",
  "icon": "bx-pointer",
  "tags": ["scrum", "agile"],
  "experience": [{
    "title": "FIFA Technologie Buitenspel",
    "task": "Projectleider / Solutions Architect",
    "tags": ["team", "lead", "solutions", "architect"],
    "outcome": ["Back-end voor streaming", "Communicatie met PO & PM", "Coördinatie tussen 2 - 3 teams", "Uitwerken van technische details", "3D visualisatie met meerdere camera's"],
    "link": ["https://www.fifa.com/technical/media-releases/semi-automated-offside-technology-to-be-used-at-fifa-world-cup-2022-tm", "https://www.fifa.com/technical/football-technology/news/semi-automated-offside-tech-aids-referees-and-fans-at-fifa-club-world-cup-tm", "https://www.fifa.com/technical/football-technology/news/limb-tracking-technology-offers-new-array-of-possibilities"],
    "image": ["./images/consult/bs-fifa-app-offside-01.jpg"],
    "copyright": "Beyond Sports B.V."
  }, {
    "title": "NFL Wild Card 2021",
    "task": "Back-end Lead",
    "tags": ["team", "lead", "back-end", "ontwikkelaar"],
    "outcome": ["2 Emmy® Awards", "Coördinatie tussen teams", "Live bewerking van streamdata", "Volledige stabiliteit tijdens stream"],
    "link": ["https://www.theverge.com/2021/1/11/22224770/nickelodon-nfl-wild-card-new-orleans-saints-chicago-bears-spongebob-squarepants-slime/", "https://twitter.com/CBSSports/status/1348411821775085577", "https://www.linkedin.com/posts/beyondsports_sportsemmy-activity-6866302858023755776-USMI"],
    "image": ["./images/techlead/bs-nfl-nick-wildcard-01.jpg", "./images/techlead/bs-nfl-nick-wildcard-02.jpg"],
    "copyright": "Beyond Sports B.V. / Paramount Global"
  }, {
    "title": "Racespel Met Dieren",
    "task": "Begeleider",
    "tags": ["begeleiding", "advies"],
    "outcome": ["Team met 4 studenten begeleid", "Geholpen met technisch overzicht", "Met succes afgestudeerde studenten", "Ontwikkelkennis gedeeld & codereviews"]
  }, {
    "title": "Race Tracker",
    "task": "Ontwikkelaar & Tweede Lead",
    "tags": ["ontwikkelaar", "tweede lead"],
    "outcome": ["Pixel-perfecte en vlotte webapp", "Real-time streaming posities", "Scrum-ondersteuning voor PM"],
    "link": null,
    "copyright": null
  }]
};
const layoutProps = {
  PageQuery_service_nl_techleadmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "award-winnende-producten",
      "style": {
        "position": "relative"
      }
    }}>{`Award-winnende producten`}<a parentName="h1" {...{
        "href": "#award-winnende-producten",
        "aria-label": "award winnende producten permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Met mijn hulp als ontwikkelaar en (tech) lead voor het back-end team werden er 2 Emmy® Awards gewonnen door een trots bedrijf:`}</p>
    <blockquote>
      <p parentName="blockquote">{`We konden niet trotser en gelukkiger zijn dat we die #sportsemmy trofeeën eindelijk in ons kantoor hebben`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.linkedin.com/posts/beyondsports_sportsemmy-activity-6866302858023755776-USMI/"
        }}>{`Bron`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "vaardigheden",
      "style": {
        "position": "relative"
      }
    }}>{`Vaardigheden`}<a parentName="h2" {...{
        "href": "#vaardigheden",
        "aria-label": "vaardigheden permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Een proactieve houding`}</li>
      <li parentName="ul">{`Ervaring met aansturen van teams tot 10+ personen`}</li>
      <li parentName="ul">{`In staat om complexe productwensen op te splitsen in concrete stappen`}</li>
      <li parentName="ul">{`Geoefend in het begrijpen, migreren en refactoren van grote codebases`}</li>
      <li parentName="ul">{`Voortdurende communicatie met belanghebbenden (PO, PM, teamleden, extern)`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      